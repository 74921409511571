/* eslint-disable no-unused-vars */
import I18n from './vendor/I18n.js';

(() => {
    document.addEventListener('DOMContentLoaded', () => {
        window.translator = new I18n;
    });
})();

window.trans = function () {
    return window.translator.trans(...arguments);
};

window.__ = function () {
    return window.trans(...arguments);
};

window.trans_choice = function () {
    return window.translator.trans_choice(...arguments);
};
